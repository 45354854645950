import store from "@/state/store";

export default [
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/account/login.vue"),
  //   meta: {
  //     title: "Login",
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in and the route doesn't require authentication
  //       if (store.getters["auth/loggedIn"] && !routeTo.meta.authRequired) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  // {
  //   path: "/logout",
  //   name: "logout",
  //   meta: {
  //     title: "Logout",
  //     authRequired: true,
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // Clear local storage on logout
  //       localStorage.clear();
  //       sessionStorage.clear();
  //       next();
  //     },
  //   },
  //   component: () => import("../views/auth/logout/basic"),
  // },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Track and Trace",
    },
    component: () => import("../views/landing/public-track-trace.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/index.vue"),
  },
  {
    path: "/wms-dashboard",
    name: "WMSDashboard",
    meta: {
      title: "WMS Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/wms-dashboard.vue"),
  },
  {
    path: "/tracking",
    name: "tracking",
    meta: {
      title: "Tracking",
      authRequired: true,
    },
    component: () => import("../views/tracking/index.vue"),
  },
  {
    path: "/tracking/:status",
    name: "TrackingByStatus",
    meta: {
      title: "Tracking",
      authRequired: true,
    },
    component: () => import("../views/tracking/index.vue"),
  },
  {
    path: "/reports",
    name: "Reports",
    meta: {
      title: "Reports",
      authRequired: true,
    },
    component: () => import("../views/reports/index.vue"),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];
